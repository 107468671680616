<template>
    <div class="my-account">
        <div>
            <el-form :inline="true" :model="page"  :rules="rules" ref="pageForm" class="demo-form-inline">
                <el-form-item label="关键字">
                    <el-input v-model="page.keywords" placeholder="请输入接收人姓名/接收人手机/消费券号"></el-input>
                </el-form-item>
                <el-form-item label="面值">
                    <el-input v-model.number="page.face_price" placeholder="请输入面值"></el-input>
                </el-form-item>
                <el-form-item label="发放时间">
                    <el-date-picker
                            v-model="couponTime"
                            type="daterange"
                            @change="changeTime"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            :picker-options="pickerOptions"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="couponIssuanceRecordList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="myCoupon_table">
            <el-table :data="couponDataList">
                <el-table-column label="接收人姓名" prop="re_member_name" width="100px" ></el-table-column>
                <el-table-column label="接收人手机" prop="re_mobile" width="150px" ></el-table-column>
                <el-table-column label="消费券号" prop="voucher_number" width="180px" ></el-table-column>
                <el-table-column label="激活码" prop="voucher_pwd" width="110px" ></el-table-column>
                <el-table-column label="面值" prop="face_price" width="80px" ></el-table-column>
                <el-table-column label="发放时间" prop="create_time" width="150px" ></el-table-column>
                <el-table-column label="激活状态" prop="receive_status_name" width="80px"></el-table-column>
                <el-table-column label="激活时间" prop="receive_time" width="150px" ></el-table-column>
                <el-table-column label="激活人" prop="ac_member_name" width="90px"></el-table-column>
            </el-table>
        </div>
        <div class="myCoupon_page">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="page.page"
                    :page-size="page.page_size"
                    layout="total, prev, pager, next"
                    :total="pageTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {couponIssuanceRecord} from '@/api/member/member'
    export default {
        name: "couponDetail",
        data() {
            var checkNum = (rule, value, callback) => {
                setTimeout(() => {
                    if (!Number.isInteger(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        if (value >0) {
                            callback(new Error('必须大于0'));
                        } else {
                            callback();
                        }
                    }
                }, 1000);
            };
            return{
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6
                    },
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                couponDataList: [],
                couponTime: [],
                page:{
                    page: 1,
                    page_size: 10,
                    start_time: '',
                    end_time: '',
                    keywords:'',
                    face_price:'',
                },
                rules: {
                    face_price: [
                        { validator: checkNum, trigger: 'blur' }
                    ]
                },
                pageTotal: 0,
            }
        },
        mounted() {
            this.couponIssuanceRecordList()
        },
        methods:{
            changeTime(time) {
                if (time) {
                    this.page.start_time = time[0]
                    this.page.end_time = time[1]
                } else {
                    this.page.start_time = ''
                    this.page.end_time = ''
                }
            },
            couponIssuanceRecordList() {
              couponIssuanceRecord(this.page).then(res =>{
                    let data = res.data
                    this.couponDataList = data.list
                    this.pageTotal = data.count
                })
            },
            handleCurrentChange(val) {
                this.page.page = val
                this.couponIssuanceRecordList()
                window.scrollTo(0, 0)
            },
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
    .my-account {
        background: #ffffff;
        padding: 20px;
    }
</style>
